import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '..';
import './Layout.scss';
import { Link } from 'react-router-dom';

const Layout = (props) => {

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Thêm hiệu ứng cuộn mượt
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Outlet />
      <div className='footer-btn-group'>
        <Link to='tel:+84934379488' className="footer-btn-phone">
          <ion-icon name="call" />
        </Link>
        <Link to="https://zalo.me/0934379488" target="_blank" rel="noopener noreferrer" className="footer-btn-zalo">
          Zalo
        </Link>
        <span onClick={scrollToTop} className={!isVisible ? 'hidden' : ''}>
          <ion-icon name="arrow-up" />
        </span>
      </div>
      <Footer />
    </React.Fragment >
  )
}

export default Layout;